import { useState, KeyboardEvent } from "react";
import axios from "../../../utils/axios";
import { RootState } from "redux/store";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../redux/authSlice";
import { useModal } from "../../../contexts/ModalProvider";
import { useSnackbar } from "../../../contexts/SnackbarProvider";
import "./NameModal.scss";
import Button from "../../buttons/Button";

const NameModal = () => {

  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [firstName, setFirstName] = useState<string>(userInfo.firstName || "");
  const [lastName, setLastName] = useState<string>(userInfo.lastName || "");

  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const { openErrorSnackbar } = useSnackbar();

  const updateName = async () => {
    try {
      const response = await axios.put("/api/users/user", {
        first_name: firstName,
        last_name: lastName
      });

      if (response.status === 200) {
        // Determine whether the user's info should be remembered based on the existing storage
        const rememberMe = Boolean(localStorage.getItem('userInfo'));
        
        dispatch(setUser({
          userInfo: {
            id: response.data.id,
            email: response.data.email,
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            is_staff: response.data.is_staff,
            main_list: response.data.main_list,
          },
          rememberMe: rememberMe
        }));

        closeModal();
        sessionStorage.removeItem("profile" + userInfo.id);
        location.reload();
      }
    } catch (error) {
      console.error(error);
      openErrorSnackbar("Někde nastala chyba. Jméno se nepodařilo změnit.");
    }
  };

  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await updateName();
    }
  };

  return (
    <div className="name-modal" onKeyDown={handleKeyPress}>
      <h2 className="font-20-b mb-4 select-none">Změnit jméno</h2>
      <div className="mb-4">
        <label className="font-14 uppercase select-none">Jméno:</label>
        <input
          className="mt-1 p-2 w-full border rounded-md"
          type="text"
          maxLength={63}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="font-14 uppercase select-none">Příjmení:</label>
        <input
          className="mt-1 p-2 w-full border rounded-md"
          type="text"
          maxLength={63}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="w-full flex justify-end">
        <Button size="medium" onClick={updateName}>Potvrdit</Button>
      </div>
    </div>
  );
};

export default NameModal;

import './GdprPage.scss';
import Navbar from "components/navbar/Navbar";
import Footer from "components/footer/Footer";
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/consts';

const GdprPage = () => {

  return (
    <>
      <Helmet>
        <title>Eduklub - Zpracování osobních údajů</title>
        <meta name="description" content="Eduklub chrání vaše osobní údaje v souladu s GDPR. Přečtěte si jak zpracováváme data, bezpečnostní opatření a o vašich právech na ochranu osobních údajů."/>
        <link rel="canonical" href={`${websiteUrl}/gdpr`} />
      </Helmet>
      <section className="gdpr-page">
        <Navbar />
        <div className="gdpr-container">
          <h1 className='font-32-b'>GDPR</h1>
          <h2 className='font-24-b'>Účel Zpracování Dat:</h2>
          <p>
            Eduklub shromažďuje a zpracovává osobní údaje (e-mail, heslo, křestní jméno, příjmení) a volitelně profilový 
            obrázek za účelem poskytování a správy přístupu k výukovým materiálům a sdílení výukových celků. Uživatelé mohou 
            nahrát výukový celek z aplikace EDUBO, přičemž tyto celky a jejich autory jsou viditelné na Eduklubu.
          </p>
          <h2 className='font-24-b'>Zabezpečení a Přístup k Údajům:</h2>
          <p>
            Eduklub zaručuje vysoký standard ochrany osobních údajů a přistupuje k nim pouze oprávněný personál. Uživatelské 
            údaje jsou chráněny před neoprávněným přístupem a zneužitím.
          </p>
          <h2 className='font-24-b'>Práva Uživatelů:</h2>
          <p>
            Uživatelé mají právo kdykoliv požádat o přístup k svým osobním údajům, požadovat jejich opravu nebo výmaz, nebo omezení 
            zpracování. Pro výkon těchto práv mohou uživatelé kontaktovat Eduklub na podpora@eduklub.cz.
          </p>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default GdprPage;
import { useModal } from '../../contexts/ModalProvider';
import './PasswordReset.scss'
import Login from 'components/authetication/Login';
import axios from "../../utils/axios";
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useState } from 'react';

const PasswordReset = () => {
  const { showModal, closeModal } = useModal();
  const { openSuccessSnackbar, openErrorSnackbar } = useSnackbar();
  const [ inputValue, setInputValue ] = useState<string>("");
  const [ error, setError ] = useState<string | undefined>(undefined);

  const handleLogin = () => {
    showModal(<Login/>)
  }

  const resetValidation = () => {
    setError(undefined);
    if (!inputValue) {
      setError("Toto pole je povinné");
      return;
    }
    // eslint-disable-next-line no-useless-escape
    if (!inputValue.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      setError("E-mail není ve validním formátu");
      return;
    }
    handleReset();
  }

  const handleReset = () => {
    axios.post("/api/users/request-reset-password", {
      email: inputValue,
      location: "/"
    })
    .then(() => {
      openSuccessSnackbar("E-mail byl úspěšně odeslán!");
      closeModal();
    }).catch(err => {
      if (!err.response.data.en) {
        console.error(err);
        setError("Někde nastala chyba zkuste to znovu");
        openErrorSnackbar("Někde nastala chyba zkuste to znovu!");
      } else if (err.response.data.en.includes("Invalid")) {
        setError("Nesprávný e-mail");
        openErrorSnackbar("Nesprávný e-mail!");
      } else {
        console.error(err);
        setError("Někde nastala chyba zkuste to znovu")
        openErrorSnackbar("Někde nastala chyba zkuste to znovu!");
      }
    })
  }

  return (
    <section className='password-reset'>
      <h1 className='font-24-b'>Zapomenuté heslo</h1>
      <label className='font-14'>E-mail:</label>
      <input className={`${error ? "border-red-600" : ""}`} type="email" placeholder='Zadejte e-mail...' onChange={(e) => {setInputValue(e.target.value); if(error)setError(undefined)}}/>
      <p className={`${error ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{error}!</p>
      <div className='buttons'>
        <button className='back-button s-green-bg-h p-green' type="button" onClick={handleLogin}>Zpět</button>
        <button className='reset-button p-green-bg-h l-green' onClick={resetValidation}>Odeslat</button>
      </div>
    </section>
  )
}

export default PasswordReset;
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <footer className="footer s-green-bg">
      <header>Eduklub © 2024</header>
      <Link to={`/gdpr`}>GDPR</Link>
    </footer>
  );
};

export default Footer;
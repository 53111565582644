import Info from "./Info";
import SettingsField from "./SettingsField";
import NameModal from "./modals/NameModal";
import PasswordModal from "./modals/PasswordModal";
import { useModal } from "../../contexts/ModalProvider";
import EmailIcon from 'images/email.svg?react';
import NameTagIcon from 'images/nametag.svg?react';
import KeyIcon from 'images/key.svg?react';
import { useSelector } from 'react-redux';
import axios from "../../utils/axios";
import { removeUser } from '../../redux/authSlice';
import { store, RootState } from '../../redux/store';
import FaRightFromBracket from 'images/fa-right-from-bracket.svg?react';
import "./ProfileSettings.scss";
import GeneralModal from "../general-modals/GeneralModal";
import { useSnackbar } from "contexts/SnackbarProvider";
import { useNavigate } from "react-router-dom";

const ProfileSettings = () => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const { email, firstName, lastName } = userInfo;
  const { showModal, closeModal } = useModal();
  const { openSuccessSnackbar, openErrorSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const showNameModal = () => {
    showModal(<NameModal />);
  }

  const showPasswordModal = () => {
    showModal(<PasswordModal />);
  }

  const signOut = () => {
    store.dispatch(removeUser());
    axios.defaults.headers.common['Authorization'] = '';
  }

  const deleteUser = (inputValue: string) => {
    const request = {
      password: inputValue
    };
    axios.post(`/api/users/${userInfo.id}/delete`, request)
    .then(() => {
      signOut();
      openSuccessSnackbar('Váš účet byl úspěšně smazán!');
      closeModal();
      navigate("/");
    })
    .catch((error) => {
      if (error.response.status === 403) {
        openErrorSnackbar('Zadané heslo je nesprávné!');
        return;
      }
      openErrorSnackbar('Váš účet se nepodařilo smazat!');
      console.error(error.response);
    });
  }

  return (
    <div className="profile-settings">
      <h1 className="p-blue font-32">Nastavení profilu</h1>
      <div className="flex flex-col items-center">
        <Info userId={String(userInfo.id)} profileBadge/>
        <div className="delete-wrapper">
          <button className="delete-button l-red-bg-h" onClick={() => showModal(<GeneralModal text={"Opravdu chcete smazat svůj účet?"} actionOnClickWparam={deleteUser} input={"Napište své heslo:"}/>)}>Smazat účet</button>
        </div>
        <div className="flex flex-wrap gap-y-6 w-3/4 mt-3">
          <div className="w-1/2 px-4">
            <SettingsField label="E-mail" value={email} Icon={EmailIcon} />
          </div>
          <div className="w-1/2 px-4">
            <SettingsField label="Jméno a příjmení" value={firstName + " " + lastName} Icon={NameTagIcon} onClick={showNameModal} />
          </div>
          <div className="w-1/2 px-4">
            <SettingsField label="Heslo" Icon={KeyIcon} onClick={showPasswordModal} />
          </div>
          <div className="w-1/2 px-4">
            <SettingsField label="Odhlásit se" Icon={<FaRightFromBracket className="m-1 mr-3 h-10 w-10 p-green" />} onClick={signOut} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;

import './Navbar.scss'
import EduklubLogo from 'images/eduklub-full-logo.svg?react'
import Heart from 'images/heart.svg?react'
import ShoppingCart from 'images/shopping-cart.svg?react'
import { useModal } from '../../contexts/ModalProvider';
import Login from '../authetication/Login';
import { useDispatch, useSelector } from 'react-redux';
import ProfileBadge from '../profile-badge/ProfileBadge';
import PillButton from '../buttons/PillButton';
import ChooseMaterialModal from '../upload-material/ChooseMaterialModal';
import { Link } from 'react-router-dom';
import { navigate } from '../../redux/navigateSlice';
import { useAuth } from '../../contexts/AuthProvider';

const Navbar = () => {
  const { showModal } = useModal();
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const dispatch = useDispatch();
  const { checkIsLoggedIn } = useAuth();

  const handleLoginOpen = () => {
    showModal(<Login/>);
  }

  const handleAddMaterial = () => {
    checkIsLoggedIn(Boolean(userInfo.id));
    if (userInfo.id) showModal(<ChooseMaterialModal/>);
  }

  const handleHearthClick = () => {
    if (window.location.pathname.split("/")[1] === "lists") return;
    dispatch(navigate("/lists/0"));
  }

  const handleCartClick = () => {
    if (window.location.pathname.split("/")[1] === "lists") return;
    dispatch(navigate("/lists"));
  }

  return (
    <nav className='navbar'>
      <div className='navbar-container'>
        <div className='top-bar'>
          <Link to={"/"} aria-label="Eduklub logo"><EduklubLogo className='logo'/></Link>
          <div className='user-nav'>
            {userInfo.email ?
              <ProfileBadge aria-label="Profil" userId={userInfo.id} nav={true} border={true} onClick={() => dispatch(navigate("/me"))}/>
            :
              <ProfileBadge aria-label="Přihlásit se" userId={undefined} nav={true} onClick={handleLoginOpen}/>
            }
            <Heart aria-label="Oblíbené" className='p-green-h' onClick={handleHearthClick}/>
            <ShoppingCart aria-label="Košík" className='p-green-h' onClick={handleCartClick}/>
          </div>
        </div>
        <div className='navigation s-green-bg'>
          <div className='tabs'>
            <Link className='font-16-b p-blue-h' to={"/"}>Plány výuky</Link>
            <a className='font-16-b p-blue-h' href='https://www.eduklub.cz/category/aktuality/'>Články</a>
            <a className='font-16-b p-blue-h' href='https://www.eduklub.cz/category/videa/'>Videa</a>
            <a className='font-16-b p-blue-h' href='https://www.eduklub.cz/forum/'>Fórum</a>
          </div>
          <PillButton className='ml-auto mr-[24px]' onClick={handleAddMaterial}>+ Nahrát materiál</PillButton>
        </div>
      </div>
    </nav>
  )
}
export default Navbar;

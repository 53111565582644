import Upload from 'images/upload-icon.svg?react';
import './ChooseMaterialModal.scss';
import { useModal } from 'contexts/ModalProvider';
import Button from '../buttons/Button';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { iMutationMaterialData, iMaterialData } from 'interfaces/materialData';
import LoadingScreen from '../loading-screen/LoadingScreen';
import { getCachedKeyword } from 'utils/getCachedKeyword';
import { getSpecificCategory } from 'utils/getCachedCategories';

const UploadMaterialModal = lazy(() => import("components/upload-material/UploadMaterialModal"));

const blankMaterialData: iMaterialData = {
  name: "",
  subjectName: "",
  description: "",
  lessons: [],
  curriculums: []
}

type ChooseMaterialModalProps = {
  parent?: iMutationMaterialData;
}

const ChooseMaterialModal = ({parent}: ChooseMaterialModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>(new File([], ''));
  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {openErrorSnackbar} = useSnackbar();
  const {showModal, closeModal} = useModal();
  const [materialData, setMaterialData] = useState<iMaterialData | undefined>(undefined);

  useEffect(() => {
    if (materialData === undefined) return;
    showModal(
      <Suspense fallback={<LoadingScreen modal/>}>
        <UploadMaterialModal rawMaterialData={materialData} file={file}/>
      </Suspense>
    );
  }, [materialData])

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) uploadFile(e.dataTransfer.files[0]);
  };

  const handleChange = (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (e.target.files && e.target.files[0]) uploadFile(e.target.files[0]);
  };

  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const refuseUpload = () => {
    openErrorSnackbar("Soubor je ve špatném formátu!");
    closeModal();
    setLoading(false);
  };

  const uploadFile = async (file: File) => {
    setFile(file);
    const fileExtension = `.${file.name.split('.').pop()}`.toLowerCase();
    if (fileExtension !== '.zip') {
      refuseUpload();
      return;
    }

    const JSZip = (await import('jszip')).default;
    const zipParser = new JSZip();
    try {
      const zip = await zipParser.loadAsync(file)
      const numberOfFiles = Object.keys(zip.files).length;
      if (numberOfFiles === 0) {
        refuseUpload();
        return;
      }

      if (numberOfFiles <= 1) {
        openErrorSnackbar("Ve výukovém celku není žádná hodina!");
        closeModal();
        setLoading(false);
        return;
      }

      let fileNames: string[] = [];
      for (const file in zip.files) {
        if (file.includes('__MACOSX') || (!file.endsWith('.html') && !file.endsWith('.json'))) {
          continue;
        }
        const fileName = file.slice(0,-5);
        if (fileNames.includes(fileName)) {
          fileNames = fileNames.filter((name) => name !== fileName);
        } else {
          fileNames.push(fileName);
        } 
      }

      if (fileNames.length !== 1) {
        refuseUpload();
        return;
      }

      const userDataFile: string = fileNames[0] + '.json';

      const newMaterialData: iMaterialData = JSON.parse(JSON.stringify(blankMaterialData));
      const data = await zip.file(userDataFile)?.async("string")
      if (data === undefined) {
        refuseUpload();
        return;
      }
      const userData = JSON.parse(data);
      newMaterialData.name = userData.name;
      newMaterialData.subjectName = userData.subject_name;
      newMaterialData.description = userData.notes;

      userData.lessons.forEach((lesson: any) => {
        newMaterialData.lessons.push(lesson.name);
      });

      for (const fileName in zip.files) {
        // Focus on nested zip files and exclude __MACOSX folder
        if (!fileName.endsWith('.zip') || fileName.includes('__MACOSX')) continue;
    
        const nestedZipFile = await zip.file(fileName)?.async("blob");
        if (!nestedZipFile) {
          refuseUpload();
          return;
        }

        const nestedZipParser = new JSZip();
        const nestedZip = await nestedZipParser.loadAsync(nestedZipFile);
        for (const nestedFileName in nestedZip.files) {
          if (!nestedFileName.endsWith('.json') || nestedFileName.includes('__MACOSX')) continue;
    
          const fileContent = await nestedZip.file(nestedFileName)?.async("string");
          if (!fileContent) {
            refuseUpload();
            return;
          }

          const fileJson = JSON.parse(fileContent);
          if (fileName === userDataFile) continue;

          for (let i = 0; i < fileJson.curriculums.length; i++) {
            let curriculum = fileJson.curriculums[i];
            if (!newMaterialData.curriculums.includes(curriculum) && curriculum !== "") {
              if (curriculum.length > 1023) curriculum = curriculum.slice(0, 1023);
              newMaterialData.curriculums.push(curriculum);
            }
          }
        }
      }

      if (parent) {
        if (typeof parent.subject !== "number") return;
        if (typeof parent.gradeType !== "number") return;
        if (typeof parent.grade !== "number") return;
        if (typeof parent.language !== "number") return;
        if (typeof parent.category !== "number" && parent.category !== null) return;
        const fetchedSubject = await getCachedKeyword(parent.subject, "subjects", true);
        const fetchedGradeType = await getCachedKeyword(parent.gradeType, "grade-types", true);
        const fetchedGrade = await getCachedKeyword(parent.grade, "grades", true);
        const fetchedLanguage = await getCachedKeyword(parent.language, "languages", true);
        const fetchedCategory = await getSpecificCategory(parent.category || undefined);
        newMaterialData.parent = parent.id;
        newMaterialData.description = parent.description;
        newMaterialData.subject = fetchedSubject;
        newMaterialData.gradeType = fetchedGradeType;
        newMaterialData.grade = fetchedGrade;
        newMaterialData.category = fetchedCategory;
        newMaterialData.keywords = parent.keywords;
        newMaterialData.language = fetchedLanguage;
      }
    
      setMaterialData(newMaterialData);
    } catch (error) {
      console.error(error);
      refuseUpload();
      return;
    }
  };

  return (
    <div className='choose-material-modal'>
      <div className="modal-header">
        {parent ?
          <h3 className='font-20-b'>Přidat mutaci</h3>
        :
          <h3 className='font-20-b'>Přidat materiál</h3>
        }
      </div>
      {loading ?
        <LoadingScreen/>
      :
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <Upload onDrop={() => uploadFile}/>
          <h3 className='font-20-b p-blue'>Přetáhněte sem zip soubor výukového celku</h3>
          <p className='modal-text p-blue'>Vyexportujte výukový celek z aplikace EDUBO v menu</p>
          <input ref={inputRef} type="file" hidden id="input-file-upload" multiple={true} onChange={handleChange} accept=".zip"/>
          { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
          <Button size='large' onClick={onButtonClick}>Vyberte soubory</Button>
        </form>
      }
    </div>
  )
}

export default ChooseMaterialModal;
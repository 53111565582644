import CertifiedIcon from 'images/certified-badge.svg?react'
import Tooltip from '../tooltip/Tooltip';
import "./CertifiedBadge.scss"

interface CertifiedBadgeProps {
  text?: boolean,
  large?: boolean,
  customClass?: string
}

const CertifiedBadge = ({text, large, customClass}: CertifiedBadgeProps) => {
  return (
    <Tooltip text={"Certifikováno"}>
      <div data-tooltip-target="tooltip-light" data-tooltip-style="light" className={`certified-badge l-purple-bg-h ${customClass}`}>
        <CertifiedIcon className={`p-purple certified-icon ${!text ? ' l-purple-bg-h' : null} ${large ? 'large-icon' : 'small-icon'}`} />
        {text && <span className='certified-text'>Certifikováno</span>}
      </div>
    </Tooltip>
  );
};

export default CertifiedBadge;
import CertifiedBadge from "../certified-badge/CertifiedBadge";
import HeartButton from "../buttons/HeartButton";
import Rating from "../rating/Rating";
import "./RecommendedUnit.scss";
import ProfileBadge from "../profile-badge/ProfileBadge";
import Keyword from "../keyword/Keyword";
import { iTeachingUnit } from "../../interfaces/teachingUnit";
import RemoveIcon from 'images/remove-icon.svg?react';
import { getCachedKeyword } from "../../utils/getCachedKeyword";
import { useEffect, useState } from "react";
import { htmlToPlainText } from "../../utils/htmlToPlainText";
import { Link } from 'react-router-dom';
import { useModal } from '../../contexts/ModalProvider';
import GeneralModal from '../general-modals/GeneralModal';
import axios from "utils/axios";
import { useDispatch } from "react-redux";
import { setReloadTeachingUnit } from "redux/utilsSlice";
import { useSnackbar } from "contexts/SnackbarProvider";

type RecommendedUnitProps = {
  removable?: boolean;
  parentId?: number;
}

const RecommendedUnit = ({ id, name, number_of_lessons, _tags, description, average_rating, number_of_ratings, number_of_downloads, certificated, author, isInListPage, removeUnitFromList, selectedList, subject, grade, grade_type, mutations, removable = false, parentId }: iTeachingUnit & RecommendedUnitProps) => {
  const [subjectName, setSubjectName] = useState<string | null>(null);
  const [gradeName, setGradeName] = useState<string | null>(null);
  const [gradeTypeName, setGradeTypeName] = useState<string | null>(null);
  const {showModal, closeModal} = useModal();
  const { openSuccessSnackbar, openErrorSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (subject) fetchKeyword("subjects", setSubjectName, subject);
    if (grade) fetchKeyword("grades", setGradeName, grade);
    if (grade_type) fetchKeyword("grade-types", setGradeTypeName, grade_type);
  }, [subject]);

  const removeAlternativeOnClick = () => {
    closeModal();
    axios.delete(`/api/teaching-units/${parentId}/alternatives/${id}/delete`)
    .then(() => {
      dispatch(setReloadTeachingUnit("alternatives"));
      openSuccessSnackbar('Alternativa byla úspěšně smazána!');
    })
    .catch((error) => {
      openErrorSnackbar('Alternativu se nepodařilo smazat!');
      console.error(error.response);
    });
  }

  const fetchKeyword = async (type: string, setter: React.Dispatch<React.SetStateAction<string | null>>, value: number) => {
    const name = await getCachedKeyword(value, type);
    setter(name);
  };

  const handleRemoveClick = () => {
    if (selectedList !== null) {
      removeUnitFromList(selectedList, id);
    }
  };

  if (isInListPage) {
    return (
      <div className="recommended-unit list-page-unit">
        <div className="title-row">
          <h3 className="unit-title p-blue font-20-b">
            <Link to={`/teaching-unit/${id}`}><span>{name}</span></Link>
          </h3>
          <div className="right-controls">
            <button className="remove-button l-red-bg-h" onClick={handleRemoveClick}>
              <RemoveIcon width="20px" height="20px"/>
            </button>
            <div className="set-and-mutations">
              <p className="unit-set font-16-b p-blue">
                set {number_of_lessons} hodin 
              </p>
            </div>
          </div>
        </div>
        <div className="unit-author">
          <ProfileBadge userId={author} name={true} />
        </div>
      </div>
    );
  }

  return (
    <div className="recommended-unit">
      <h3 className="unit-title p-blue font-20-b"><Link to={`/teaching-unit/${id}`}><span>{name}</span></Link></h3>
      <p className="unit-set font-12">set {number_of_lessons} hodin {mutations.length > 0 && <><span className="p-purple">|</span> {mutations.length} {mutations.length > 4 ? "mutací" : "mutace"}</>}</p>
      <div className="unit-author">
        <ProfileBadge userId={author} name={true} />
      </div>
      <ul className="unit-keywords">
        <>
          {subjectName && <Keyword key={subjectName} keyword={subjectName}/>}
          {gradeTypeName && <Keyword key={gradeTypeName} keyword={gradeTypeName}/>}
          {gradeName && <Keyword key={gradeName} keyword={gradeName}/>}
          {_tags.map((keyword, index) => (
            <Keyword key={index} keyword={keyword.name}/>              
          ))}
        </>
      </ul>
      <p className="unit-description font-14">{htmlToPlainText(description)}</p>
      <div className="unit-controls">
        <div className="left-controls">
          <HeartButton teachingUnitId={id} teachingUnitName={name}/>
          {removable &&
            <RemoveIcon className='remove-icon l-red-bg-h' onClick={() => showModal(<GeneralModal text={"Opravdu chcete odebrat alternativu?"} actionOnClick={removeAlternativeOnClick}/>)}/>
          }
        </div>
        <div className="right-controls">
          <div className="unit-stats">
            <div className="font-12">{number_of_ratings} hodnocení</div>
            <div className="font-12">{number_of_downloads} stažení</div>
          </div>
          <Rating rating={average_rating ? Math.round(average_rating * 10) / 10 : 0} />
          {certificated &&
            <CertifiedBadge large={true}/>
          }
        </div>
      </div>
    </div>
  );
};

export default RecommendedUnit
import { useSelector } from "react-redux";
import Navbar from "../components/navbar/Navbar";
import ProfileSettings from "../components/profile/ProfileSettings";
import Published from "../components/profile/Published";
import './ProfilePage.scss';
import { RootState } from "redux/store";
import Footer from "components/footer/Footer";
import { Helmet } from "react-helmet-async";
import { websiteUrl } from "utils/consts";

const ProfilePage = () => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  return (
    <>
      <Helmet>
        <title>Eduklub - Vlastní profil</title>
        <meta name="description" content="Eduklub nabízí široký výběr výukových plánů pro aplikaci EDUBO i PDF export pro vlastní plánování výuky. Vyhledejte plány, nahrávejte, stahujte a hodnoťte."/>
        <link rel="canonical" href={`${websiteUrl}/me`} />
      </Helmet>
      <div className="profile-page">
        <Navbar />
        <div className="profile-container">
          <ProfileSettings />
          <Published header userId={userInfo.id} />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default ProfilePage;
import HeartIcon from 'images/heart.svg?react';
import SavedList from '../saved-list/SavedList';
import { useModal } from "../../contexts/ModalProvider";
import "./HeartButton.scss";
import { useAuth } from '../../contexts/AuthProvider';
import { useSelector } from 'react-redux';

interface HeartButtonProps {
  teachingUnitId: number;
  teachingUnitName: string;
  reloadLists?: () => void;
}

const HeartButton = ({teachingUnitId, teachingUnitName, reloadLists}: HeartButtonProps) => {
  const { showModal } = useModal();
  const { checkIsLoggedIn } = useAuth();
  const userInfo = useSelector((state: any) => state.auth.userInfo);

  const handleHeartClick = () => {
    checkIsLoggedIn(Boolean(userInfo.id));
    if (userInfo.id) showModal(<SavedList teachingUnitName={teachingUnitName} teachingUnitId={teachingUnitId} reloadLists={reloadLists} />);
  };

  return (
    <div className="heart-button" onClick={handleHeartClick}>
      <HeartIcon className="heart-icon s-blue-h l-blue-bg-h" />
    </div>
  );
};

export default HeartButton;

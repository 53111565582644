import { iTeachingUnit } from 'interfaces/teachingUnit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../utils/axios';
import { RootState } from '../../redux/store';
import RecommendedUnit from '../recommended-unit/RecommendedUnit';
import PillButton from '../buttons/PillButton';
import LoadingScreen from '../loading-screen/LoadingScreen';
import './Published.scss';
import { getTeachingUnitRowAmount } from '../../utils/getTeachingUnitRowAmount';

interface PublishedProps {
  header?: boolean;
  userId: number | undefined;
}

const Published = ({header=false, userId}: PublishedProps) => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [teachingUnits, setTeachingUnits] = useState<iTeachingUnit[]>([]);
  const [next, setNext] = useState<string>("");
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getTeachingUnits();
  }, [])

  const getTeachingUnits = async () => {
    const pageAmount = getTeachingUnitRowAmount() * 2;
    const newTeachingUnits = await axios.get(`/api/teaching-units?author=${userId}&page-size=${pageAmount}&is-mutation=0`);
    setTeachingUnits(newTeachingUnits.data.results);
    if (newTeachingUnits.data.next) setNext(newTeachingUnits.data.next);
    else setNext("");
    setFirstLoading(false);
  }

  const getMoreTeachingUnits = async () => {
    setLoading(true);
    const newTeachingUnits = await axios.get(next);
    setTeachingUnits([...teachingUnits, ...newTeachingUnits.data.results]);
    if (newTeachingUnits.data.next) setNext(newTeachingUnits.data.next);
    else setNext("");
    setLoading(false);
  }

  return (
    <div className="published">
      {header && <h1 className="p-blue font-32 mt-8">Publikované materiály autorem: „{userInfo.firstName + " " + userInfo.lastName}“</h1>}
      {firstLoading ?
        <LoadingScreen upper/>
      :
        <div className="units-container">
          {teachingUnits.map((data, index) => (
            <RecommendedUnit key={index} {...data} />
          ))}
        </div>
      }
      <div className="w-full flex justify-center mt-4">
        {next !== "" &&
          <>
            {loading ?
              <LoadingScreen upper/>
            :
              <PillButton color='secondary' onClick={getMoreTeachingUnits}>Zobrazit další</PillButton>
            }
          </>
        }
      </div>
    </div>
  )
};

export default Published;
export type iUserInfo = {
  id: number | undefined;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  is_staff: boolean | undefined;
  main_list: number | undefined;
}

export const blankUserInfo: iUserInfo = {
  id: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  is_staff: undefined,
  main_list: undefined
}

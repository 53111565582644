import { useState } from "react";
import Button from "../../../buttons/Button";
import "./GuidesAdviceModal.scss";
import { useModal } from "../../../../contexts/ModalProvider";
import { useSnackbar } from "../../../../contexts/SnackbarProvider";
import axios from "utils/axios";
import pureAxios from "axios";
import { setReloadTeachingUnit } from "redux/utilsSlice";
import { useDispatch } from "react-redux";

type GuidesAdviceModalProps = {
  guide: boolean;
  id: string | undefined;
}

const GuidesAdviceModal = ({guide, id}: GuidesAdviceModalProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const { closeModal } = useModal();
  const { openSuccessSnackbar, openErrorSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const addArticle = async () => {
    const regex = /https:\/\/www\.eduklub\.cz\/(\d{4}\/\d{2}\/\d{2})\/([^/]+)\//i;
    const match = inputValue.match(regex);
    if (match === null) {
      if (guide) openErrorSnackbar("Článek musí být odkaz na článek na eduklubu!");
      else openErrorSnackbar("Návod musí být odkaz na článek na eduklubu!");
      return;
    }
    try {
      await pureAxios.get(inputValue);
    } catch {
      if (guide) openErrorSnackbar("Článek musí být odkaz na článek na eduklubu!");
      else openErrorSnackbar("Návod musí být odkaz na článek na eduklubu!");
      return;
    }
    axios.post(`/api/teaching-units/${id}/${guide ? "guide-urls" : "advice-urls"}`,
      {"url": inputValue})
    .then(() => {
      dispatch(setReloadTeachingUnit("guidesAdvice"));
      closeModal();
      openSuccessSnackbar(`${guide ? 'Návod' : 'Článek'} byl přidán!`);
    })
    .catch((err: any) => {
      if (err.response.status === 409) {
        openErrorSnackbar(`Tento ${guide ? "návod" : "článek"} je již přiřazen!`);
        return;
      } else {
        openErrorSnackbar(`Nepodařilo se přidat ${guide ? "návod" : "článek"}!`);
        console.error(err);
      }
    })
  }

  return (
    <div className="guides-advice-modal">
      <h2 className="font-16-b">Vložte odkaz na {guide ? 'návod' : 'článek'}</h2>
      <input className="guides-advice-input" value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
      <div className="footer-guides-advice">
        <Button size={"medium"} color={"primary"} onClick={addArticle}>Přidat</Button>
      </div>
    </div>
  )
};

export default GuidesAdviceModal;
import { useEffect, useState } from 'react';
import './GuidesAdviceWindow.scss'
import Button from "../../buttons/Button";
import PillButton from "../../buttons/PillButton";
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from "../../../contexts/ModalProvider";
import { RootState } from 'redux/store';
import GuidesAdviceModal from './guides-advice-modal/GuidesAdviceModal'
import pureAxios from 'axios';
import axios from 'utils/axios';
import LoadingScreen from 'components/loading-screen/LoadingScreen';
import { Link } from 'react-router-dom';
import RemoveIcon from 'images/remove-icon.svg?react';
import GeneralModal from "components/general-modals/GeneralModal";
import { useSnackbar } from 'contexts/SnackbarProvider';
import { setReloadTeachingUnit } from 'redux/utilsSlice';

type scrapedData = {
  backgroundImage: string | undefined;
  header: string | undefined;
  description: string | undefined;
  url: string;
}

type link = {
  id: number;
  url: string;
}

type deleteLink = {
  type: string;
  id: number;
}

type GuidesAdviceProps = {
  isAuthor: boolean;
  id: string | undefined;
  adviceLinks: link[];
  guideLinks: link[];
}

const GuidesAdviceWindow = ({isAuthor, id, adviceLinks, guideLinks}: GuidesAdviceProps) => {
  const [shownAdviceMaxCount, setShownAdviceMaxCount] = useState<number>(4)
  const [shownGuidesMaxCount, setShownGuidesMaxCount] = useState<number>(4)
  const [scrapedAdvices, setScrapedAdvices] = useState<scrapedData[]>([]);
  const [scrapedGuide, setScrapedGuide] = useState<scrapedData[]>([]);
  const [deletingLink, setDeletingLink] = useState<deleteLink>({type: "", id: 0});
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const { showModal, closeModal } = useModal();
  const { openSuccessSnackbar, openErrorSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    setScrapedGuide([]);
    setScrapedAdvices([]);
    let scrapePromises = adviceLinks.map(obj => scrapeWebsite(obj.url, "advice"));
    scrapePromises = guideLinks.map(obj => scrapeWebsite(obj.url, "guide"));

    Promise.all(scrapePromises)
      .catch(error => {
        console.error('Error while scraping pages:', error);
      });
  }, [adviceLinks, guideLinks])

  useEffect(() => {
    if (deletingLink.type === "" || deletingLink.id === 0) return;
    showModal(<GeneralModal text={"Opravdu chcete smazat tento článek?"} actionOnClick={removeLink}/>);
  }, [deletingLink])

  const scrapeWebsite = async (url: string, type: string) => {
    try {
      const response = await pureAxios.get(url)
      const parser = new DOMParser();
      const document = parser.parseFromString(response.data, 'text/html');

      const backgroundImageUrl = document.querySelector('#a13-user-css-inline-css')?.textContent?.match(/background-image:url\((['"]?)(.*?)\1\)/)?.[2] || undefined;

      const headerText = document.querySelector('header.title-bar.outside')?.querySelector('.page-title')?.textContent || undefined;
      
      let descriptionText = document.querySelector('div.real-content p')?.textContent || undefined;
      if (descriptionText && descriptionText.length < 300) {
        const anotherPara = document.querySelector('div.real-content p + p')?.textContent || undefined;
        if (anotherPara) descriptionText = descriptionText + '' + anotherPara;
      }
      const newScrapedData = { url: url, backgroundImage: backgroundImageUrl, header: headerText, description: descriptionText }

      if (type === "advice") setScrapedAdvices(prevData => [...prevData, newScrapedData]);
      else setScrapedGuide(prevData => [...prevData, newScrapedData]);

    } catch (error) {
      console.error(`Error while scraping ${url}:`, error);
    }
  }

  const removeLink = async () => {
    closeModal();
    if (deletingLink.type === "" || deletingLink.id === 0) return;
    axios.delete(`/api/teaching-units/${id}/${deletingLink.type === "guide" ? 'guide-urls' : 'advice-urls'}/${deletingLink.id}/delete`)
    .then(() => {
      dispatch(setReloadTeachingUnit("guidesAdvice"));
      openSuccessSnackbar(`${deletingLink.type === "guide" ? 'Návod' : 'Článek'} byl úspěšně smazán!`);
      setDeletingLink({type: "", id: 0});
    })
    .catch((error) => {
      openErrorSnackbar(`${deletingLink.type === "guide" ? 'Návod' : 'Článek'} se nepodařilo smazat!`);
      setDeletingLink({type: "", id: 0});
      console.error(error.response);
    });
  }

  const openRemoveModal = (type: string, linkId: number) => {
    setDeletingLink({type: type, id: linkId});
  }

  return (
    <section className="guides-advice-window">
      <div className="advice">
        <div className="advice-header">
          <h3 className='font-32'>Poradna</h3>
          {(userInfo.is_staff || isAuthor) &&
            <Button color="primary" size="medium" className="add-new-advice-button" onClick={() => showModal(<GuidesAdviceModal guide={false} id={id}/>)}>+ Přidat článek</Button>
          }
        </div>
        {adviceLinks && adviceLinks.length === 0 && <p className="font-16-b no-links">Tato výuka zatím nemá žádné články</p>}
        <div className="advice-windows">
          {adviceLinks.slice(0, shownAdviceMaxCount).map((obj, index) => {
            if (adviceLinks.length !== scrapedAdvices.length) {
              return (
                <div key={index} className='link-window loading'>
                  <LoadingScreen/>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  {scrapedAdvices.filter(scrapedData => scrapedData.url === obj.url).map((scrapedData, dataIndex) => {
                    if (dataIndex !== 0) return null;
                    return (
                      <Link target="_blank" rel="noopener noreferrer" to={scrapedData.url} key={scrapedData.url} className='link-window'>
                        <img src={scrapedData.backgroundImage} alt="Background"/>
                        {(isAuthor || userInfo.is_staff) &&
                          <button className="remove-button l-red-bg-h" onClick={(e) => {e.preventDefault(); openRemoveModal("advice", obj.id)}}>
                            <RemoveIcon width="20px" height="20px"/>
                          </button>
                        }
                        <div className={`text ${(isAuthor || userInfo.is_staff) ? "text-margin" : ""}`}>
                          <h4 className='font-14-b'>{scrapedData.header}</h4>
                          <p className='font-14'>{scrapedData.description}</p>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
        <div className="advice-footer">
        {adviceLinks && adviceLinks.length > shownAdviceMaxCount && <PillButton color="secondary" size="medium" className="show-more-button" onClick={() => setShownAdviceMaxCount((prev) => prev + 4)}>Zobrazit další &#40;{adviceLinks.length - shownAdviceMaxCount}&#41;</PillButton>}
        </div>
      </div>
      <div className="guides">
        <div className="guides-header">
          <h3 className='font-32'>Návody</h3>
          {(userInfo.is_staff || isAuthor) &&
            <Button color="primary" size="medium" className="add-new-guide-button" onClick={() => showModal(<GuidesAdviceModal guide id={id}/>)}>+ Přidat návod</Button>
          }
        </div>
        {guideLinks && guideLinks.length === 0 && <p className="font-16-b no-links">Tato výuka zatím nemá žádné návody</p>}
        <div className="guides-windows">
        {guideLinks.slice(0, shownGuidesMaxCount).map((obj, index) => {
            if (guideLinks.length !== scrapedGuide.length) {
              return (
                <div key={index} className='link-window loading'>
                  <LoadingScreen/>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  {scrapedGuide.filter(scrapedData => scrapedData.url === obj.url).map((scrapedData, dataIndex) => {
                    if (dataIndex !== 0) return null;
                    return (
                      <Link target="_blank" rel="noopener noreferrer" to={scrapedData.url} key={scrapedData.url} className='link-window'>
                        <img src={scrapedData.backgroundImage} alt="Background"/>
                        {(isAuthor || userInfo.is_staff) &&
                          <button className="remove-button l-red-bg-h" onClick={(e) => {e.preventDefault(); openRemoveModal("guide", obj.id)}}>
                            <RemoveIcon width="20px" height="20px"/>
                          </button>
                        }
                        <div className={`text ${(isAuthor || userInfo.is_staff) ? "text-margin" : ""}`}>
                          <h4 className='font-14-b'>{scrapedData.header}</h4>
                          <p className='font-14'>{scrapedData.description}</p>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
        <div className="guides-footer">
          {guideLinks && guideLinks.length > shownGuidesMaxCount && <PillButton color="secondary" size="medium" className="show-more-button" onClick={() => setShownGuidesMaxCount((prev) => prev + 4)}>Zobrazit další &#40;{guideLinks.length - shownGuidesMaxCount}&#41;</PillButton>}
        </div>
      </div>

    </section>
  )
}

export default GuidesAdviceWindow;
import './ExtendedFilter.scss';
import { useEffect, useState } from 'react';
import DropdownPopover from '../dropdown-popover/DropdownPopover';
import FaChevronDwon from 'images/fa-chevron-down.svg?react';
import { iFilterObject, iFilterTag } from '../../interfaces/filter';
import { useSearchParams } from 'react-router-dom';
import Button from '../buttons/Button';
import { Signal } from '@preact/signals-react';

import useFilterLogic, {
  selectedSubjectIds,
  gradeTypes,
  subjects,
  selectedGradeTypeIds,
  grades,
  selectedGradeIds,
  certificated,
  languages,
  selectedLanguageIds,
  rating,
  filteredGrades,
  filteredSubjects,
  subjectFilter,
  mutations,
	selectedCategoryIds,
  filterQuery,
	activeTags,
	staticRating,
	staticCertificated, staticMutations
} from 'components/filter-logic/useFilterLogic';

interface ExtendedFilterProps {
	activeFilters: Signal<iFilterTag[]>;
}

const ExtendedFilter = ({activeFilters}: ExtendedFilterProps) => {
  const {handleSubjectFilter} = useFilterLogic();
  const [expanded, setExpanded] = useState<boolean>(true);
	const [skipEffect, setSkipEffect] = useState<boolean>(false);
	const [searchParams] = useSearchParams();
	const [paramsLoaded, setParamsLoaded] = useState<boolean>(false);

	useEffect(() => {
		const queryParam: string | null = searchParams?.get("q");
    if (queryParam) {setSkipEffect(true);activeTags.value = queryParam.split(",")}
		const subjectsParam: string | null = searchParams?.get("subjects");
    if (subjectsParam) loadParameters(subjectsParam.split(",").map(Number), selectedSubjectIds);
		const gradeTypesParam: string | null = searchParams?.get("grade-types");
    if (gradeTypesParam) loadParameters(gradeTypesParam.split(",").map(Number), selectedGradeTypeIds);
		const gradesParam: string | null = searchParams?.get("grades");
    if (gradesParam) loadParameters(gradesParam.split(",").map(Number), selectedGradeIds);
		const languageParam: string | null = searchParams?.get("language");
    if (languageParam) loadParameters(languageParam.split(",").map(Number), selectedLanguageIds);
		const ratingParam: string | null = searchParams?.get("rating-class");
    if (ratingParam) loadParameters(ratingParam.split(","), rating);
		const certificatedParam: string | null = searchParams?.get("certificated");
    if (certificatedParam) loadParameters(certificatedParam.split(",").map(Number), certificated);
		const mutationsParam: string | null = searchParams?.get("is-mutation");
    if (mutationsParam) loadParameters(mutationsParam.split(",").map(Number), mutations);
		const categoriesParam: string | null = searchParams?.get("category");
    if (categoriesParam) loadParameters(categoriesParam.split(",").map(Number), selectedCategoryIds);
		setSkipEffect(false)
		setParamsLoaded(true)
	}, [searchParams])

	useEffect(() => {
		if (!paramsLoaded) return;
		if (skipEffect) return;
		let query: string = "&";
		if (selectedSubjectIds.value.length > 0) query = query + "subjects=" + selectedSubjectIds.value.join(",") + "&";
		if (selectedGradeTypeIds.value.length > 0) query = query + "grade-types=" + selectedGradeTypeIds.value.join(",") + "&";
		if (selectedGradeIds.value.length > 0) query = query + "grades=" + selectedGradeIds.value.join(",") + "&";
		if (selectedLanguageIds.value.length > 0) query = query + "language=" + selectedLanguageIds.value.join(",") + "&";
		if (rating.value.length > 0) query = query + "rating-class=" + rating.value.join(",") + "&";
		if (certificated.value.length === 1) query = query + "certificated=" + certificated.value.join(",") + "&";
		if (mutations.value.length === 1) query = query + "is-mutation=" + mutations.value.join(",") + "&";
		if (selectedCategoryIds.value.length === 1) query = query + "category=" + selectedCategoryIds.value.join(",") + "&";
		if (activeTags.value.length > 0) {
      const tags: string[] = [...new Set(activeTags.value)];
      query = query + "q=" + encodeURI(tags.join(",")) + "&";
    }
    filterQuery.value = query;
  }, [selectedSubjectIds.value, selectedGradeTypeIds.value, selectedGradeIds.value, selectedLanguageIds.value, rating.value, certificated.value, mutations.value, selectedCategoryIds.value, activeTags.value, activeFilters.value, paramsLoaded])

	const loadParameters = (object: any[], setter: Signal<any[]>) => {
		if (setter === selectedSubjectIds) {
			subjects.value.filter(subject => object.includes(subject.id)).forEach((subject) => {setSkipEffect(true); toggleSelection(subject, selectedSubjectIds, true)})
		} else if (setter === selectedGradeTypeIds) {
			gradeTypes.value.filter(gradeType => object.includes(gradeType.id)).forEach((gradeType) => {setSkipEffect(true); toggleSelection(gradeType, selectedGradeTypeIds, true)})
		} else if (setter === selectedGradeIds) {
			grades.value.filter(grade => object.includes(grade.id)).forEach((grade) => {setSkipEffect(true); toggleSelection(grade, selectedGradeIds, true)})
		} else if (setter === selectedLanguageIds) {
			languages.value.filter(language => object.includes(language.id)).forEach((language) => {setSkipEffect(true); toggleSelection(language, selectedLanguageIds, true)})
		} else if (setter === rating) {
			staticRating.value.filter(rate => object.includes(rate.id)).forEach((rate) => {setSkipEffect(true); toggleSelection(rate, rating, true)})
		} else if (setter === certificated) {
			staticCertificated.value.filter(cert => object.includes(cert.id)).forEach((cert) => {setSkipEffect(true); toggleSelection(cert, certificated, true)})
		} else if (setter === mutations) {
			staticMutations.value.filter(mut => object.includes(mut.id)).forEach((mut) => {setSkipEffect(true); toggleSelection(mut, mutations, true)})
		} else if (setter === selectedCategoryIds) {
			selectedCategoryIds.value = object;
		}
	}

	const updateFilters = (name: string, id: number|string, type: any) => {
    let newList: iFilterTag[] = activeFilters.value;
    const newObject: iFilterTag = {"id": id, "name": name, "type": type}
    if (newList.some(item => item.id === newObject.id && item.name === newObject.name && item.type === newObject.type)) {
      newList = newList.filter(item => !(item.id === newObject.id && item.name === newObject.name && item.type === newObject.type));
    } else newList.push(newObject);
    activeFilters.value = newList;
  };

  const toggleSelection = (object: iFilterObject, setSelected: Signal<any[]>, firstLoad?: boolean) => {
    let newList: any[] = [...setSelected.value];
    if (newList.includes(object.id)) newList = newList.filter(item => item !== object.id);
    else newList.push(object.id);
		if (!firstLoad) {
			setSelected.value = newList;
		} else {
			if (newList.length === 0 && setSelected.value.length > 0) {
				const backedUpList: any[] = [...setSelected.value];
				setSelected.value = [];
				setSelected.value = backedUpList;
			} else {
				setSelected.value = [];
				setSelected.value = newList;
			}
		}
		if (firstLoad && activeFilters.value.length > 0) {
			return;
		} 
    updateFilters(object.name, object.id, setSelected);
  };

  const toggleStaticSelection = (item: any, setSelected: Signal<any[]>) => {
    let newList: any[] = [...setSelected.value];
    if (newList.includes(item)) newList = newList.filter(sItem => sItem !== item);
    else newList.push(item);
    setSelected.value = newList;
  };

  return (
    <section className='extended-filter'>
			<Button size='large' className='w-full extend-button' onClick={() => setExpanded(!expanded)}>Podrobný filtr</Button>
			{expanded ?
			<div className='filters'>
				<div className='filter'>
					<h2 className='font-16-b'>Předmět:</h2>
					<DropdownPopover className={"dropdown"}
						buttonClassName={"button font-16"}
						buttonChildren={
							<>
								<>Vyberte předmět</>
								<FaChevronDwon className="ml-2 h-3 chevron" />
							</>
						}
						panelClassName={"panel"}
						panelChildren={
							<> 
								<label className='font-16-b label'>Předměty</label>
								<input className="input-filter font-16 subjects" placeholder='Zadejte název předmětu...' value={subjectFilter.value} onChange={handleSubjectFilter}/>
								<div className='item-list scrollbar'>
									{filteredSubjects.value.map((object, index) => {
										return (
											<div key={index} className='item font-16' onClick={() => toggleSelection(object, selectedSubjectIds)}>
												<input type="checkbox" id={`subject-${object.id}`} checked={selectedSubjectIds.value.includes(object.id)} readOnly />
												<label htmlFor={`subject-${object.id}`} onClick={() => toggleSelection(object, selectedSubjectIds)}>{object.name}</label>
											</div>
										)
									})}
								</div>
							</>
						}
					/>
				</div>
				<div className='filter'>
					<h2 className='font-16-b'>Stupeň:</h2>
					<div className='setting-list'>
						{gradeTypes.value.map((object, index) => {
							return (
								<div className='setting' key={index} onClick={() => toggleSelection(object, selectedGradeTypeIds)}>
									<input type="checkbox" id={`gradeType-${object.id}`} checked={selectedGradeTypeIds.value.includes(object.id)} readOnly/>
									<label htmlFor={`gradeType-${object.id}`} onClick={() => toggleSelection(object, selectedGradeTypeIds)}>{object.name}</label>
								</div>
							)
						})}
					</div>          
				</div>
				<div className='filter'>
					<h2 className='font-16-b'>Ročník:</h2>
					<DropdownPopover className={"dropdown"}
						buttonClassName={"button font-16"}
						buttonChildren={
							<>
								<>Vyberte ročník</>
								<FaChevronDwon className="ml-2 h-3 chevron" />
							</>
						}
						panelClassName={"panel"}
						panelChildren={
							<> 
								<label className='font-16-b label'>Ročníky</label>
								<div className='item-list scrollbar'>
									{filteredGrades.value.map((object, index) => {
										return (
											<div key={index} className='item' onClick={() => toggleSelection(object, selectedGradeIds)}>
												<input type="checkbox" id={`grade-${object.id}`} checked={selectedGradeIds.value.includes(object.id)} readOnly />
												<label htmlFor={`grade-${object.id}`} onClick={() => toggleSelection(object, selectedGradeIds)}>{object.name}</label>
											</div>
										)
									})}
								</div>
							</>
						}
					/>
				</div>
				<div className='filter'>
					<h2 className='font-16-b'>Jazyk:</h2>
					<DropdownPopover className={"dropdown"}
						buttonClassName={"button font-16"}
						buttonChildren={
							<>
								<>Vyberte jazyk</>
								<FaChevronDwon className="ml-2 h-3 chevron" />
							</>
						}
						panelClassName={"panel"}
						panelChildren={
							<> 
								<label className='font-16-b label'>Jazyky</label>
								<div className='item-list scrollbar'>
									{languages.value.map((object, index) => {
										return (
											<div key={index} className='item' onClick={() => toggleSelection(object, selectedLanguageIds)}>
												<input type="checkbox" id={`language-${object.id}`} checked={selectedLanguageIds.value.includes(object.id)} readOnly />
												<label htmlFor={`language-${object.id}`} onClick={() => toggleSelection(object, selectedLanguageIds)}>{object.name}</label>
											</div>
										)
									})}
								</div>
							</>
						}
					/>
				</div>
				<div className='filter'>
					<h2 className='font-16-b'>Hodnocení:</h2>
					<div className='setting' onClick={() => {updateFilters("5-4 hvězdy", "5-4", rating); toggleStaticSelection("5-4", rating)}}>
						<input type="checkbox" id="rating-5-4" checked={rating.value.includes("5-4")} readOnly /> 
						<label htmlFor="rating-5-4" onClick={() => {updateFilters("5-4 hvězdy", "5-4", rating); toggleStaticSelection("5-4", rating)}}><span className='number'>5-4</span> <span className='text'>hvězdy</span></label>
					</div>
					<div className='setting' onClick={() => {updateFilters("4-3 hvězdy", "4-3", rating); toggleStaticSelection("4-3", rating)}}>
						<input type="checkbox" id="rating-4-3" checked={rating.value.includes("4-3")} readOnly /> 
						<label htmlFor="rating-4-3" onClick={() => {updateFilters("4-3 hvězdy", "4-3", rating); toggleStaticSelection("4-3", rating)}}><span className='number'>4-3</span> <span className='text'>hvězdy</span></label>
					</div>
					<div className='setting' onClick={() => {updateFilters("3-2 hvězdy", "3-2", rating); toggleStaticSelection("3-2", rating)}}>
						<input type="checkbox" id="rating-3-2" checked={rating.value.includes("3-2")} readOnly />
						<label htmlFor="rating-3-2" onClick={() => {updateFilters("3-2 hvězdy", "3-2", rating); toggleStaticSelection("3-2", rating)}}><span className='number'>3-2</span> <span className='text'>hvězdy</span></label>
					</div>
					<div className='setting' onClick={() => {updateFilters("2-1 hvězdy", "2-1", rating); toggleStaticSelection("2-1", rating)}}>
						<input type="checkbox" id="rating-2-1" checked={rating.value.includes("2-1")} readOnly />
						<label htmlFor="rating-2-1" onClick={() => {updateFilters("2-1 hvězdy", "2-1", rating); toggleStaticSelection("2-1", rating)}}><span className='number'>2-1</span> <span className='text'>hvězdy</span></label>
					</div>          
				</div>
				<div className='filter'>
					<h2 className='font-16-b'>Certifikace:</h2>
					<div className='setting' onClick={() => {updateFilters("Certifikováno", 1, certificated); toggleStaticSelection(1, certificated)}}>
						<input type="checkbox" id="certificated-1" checked={certificated.value.includes(1)} readOnly />
						<label htmlFor="certificated-1" onClick={() => {updateFilters("Certifikováno", 1, certificated); toggleStaticSelection(1, certificated)}}>Certifikované</label>
					</div>
					<div className='setting' onClick={() => {updateFilters("Bez certifikace", 0, certificated); toggleStaticSelection(0, certificated)}}>
						<input type="checkbox" id="certificated-0" checked={certificated.value.includes(0)} readOnly />
						<label htmlFor="certificated-0" onClick={() => {updateFilters("Bez certifikace", 0, certificated); toggleStaticSelection(0, certificated)}}>Bez certifikace</label>
					</div>
				</div>
				<div className='filter'>
					<h2 className='font-16-b'>Mutace:</h2>
					<div className='setting' onClick={() => {updateFilters("Pouze mutace", 1, mutations); toggleStaticSelection(1, mutations)}}>
						<input type="checkbox" id="mutations-1" checked={mutations.value.includes(1)} readOnly />
						<label htmlFor="mutations-1" onClick={() => {updateFilters("Pouze mutace", 1, mutations); toggleStaticSelection(1, mutations)}}>Pouze mutace</label>
					</div>
					<div className='setting' onClick={() => {updateFilters("Celky a mutace", 0, mutations); toggleStaticSelection(0, mutations)}}>
						<input type="checkbox" id="mutations-0" checked={mutations.value.includes(0)} readOnly />
						<label htmlFor="mutations-0" onClick={() => {updateFilters("Celky a mutace", 0, mutations); toggleStaticSelection(0, mutations)}}>Celky a mutace</label>
					</div>
				</div>
			</div>
			: null}
    </section>
  );
}

export default ExtendedFilter;
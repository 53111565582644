import FaChevronDown from 'images/fa-chevron-down.svg?react';
import { useEffect } from 'react';
import DropdownPopover from '../dropdown-popover/DropdownPopover';
import './Filter.scss';
import useFilterLogic from "../filter-logic/useFilterLogic";
import { iFilterObject, iFilterTag } from '../../interfaces/filter';
import { Signal } from '@preact/signals-react';

import {
  selectedSubjectIds,
  gradeTypes,
  selectedGradeTypeIds,
  selectedGradeIds,
  certificated,
  languages,
  selectedLanguageIds,
  rating,
  filteredGrades,
  filteredSubjects,
  subjectFilter,
  filterQuery,
  activeFilters
} from 'components/filter-logic/useFilterLogic';

const Filter = () => {
  const {
    handleSubjectFilter
  } = useFilterLogic();

  useEffect(() => {
    let query: string = "&";
    if (selectedSubjectIds.value.length > 0) query = query + "subjects=" + selectedSubjectIds.value.join(",") + "&";
    if (selectedGradeTypeIds.value.length > 0) query = query + "grade-types=" + selectedGradeTypeIds.value.join(",") + "&";
    if (selectedGradeIds.value.length > 0) query = query + "grades=" + selectedGradeIds.value.join(",") + "&";
    if (selectedLanguageIds.value.length > 0) query = query + "language=" + selectedLanguageIds.value.join(",") + "&";
    if (rating.value.length > 0) query = query + "rating-class=" + rating.value.join(",") + "&";
    if (certificated.value.length === 1) query = query + "certificated=" + certificated.value.join(",") + "&";
    filterQuery.value = query.slice(0, query.length - 1);
  }, [selectedSubjectIds.value, selectedGradeTypeIds.value, selectedGradeIds.value, selectedLanguageIds.value, rating.value, certificated.value])

  const updateFilters = (name: string, id: number|string, type: any) => {
    let newList: iFilterTag[] = [...activeFilters.value];
    const newObject: iFilterTag = {"id": id, "name": name, "type": type}
    if (newList.some(item => item.id === newObject.id && item.name === newObject.name && item.type === newObject.type)) {
      newList = newList.filter(item => !(item.id === newObject.id && item.name === newObject.name && item.type === newObject.type));
    } else newList.push(newObject);
    activeFilters.value = newList;
  };

  const toggleSelection = (object: iFilterObject, selected: Signal<number[]>) => {
    let newList: number[] = [...selected.value];
    if (newList.includes(object.id)) newList = newList.filter(item => item !== object.id);
    else newList.push(object.id);
    selected.value = newList;
    updateFilters(object.name, object.id, selected);
  };

  const toggleStaticSelection = (item: any, array: any) => {
    let newList: any[] = [...array];
    if (newList.includes(item)) newList = newList.filter(sItem => sItem !== item);
    else newList.push(item);
    return newList;
  };
  
  return (
    <div className="filter">
      <DropdownPopover className={"dropdown"}
        buttonClassName={"button font-14"}
        buttonChildren={
          <>
            <>Předmět</>
            <FaChevronDown className="ml-2 h-3 chevron" />
          </>
        }
        panelClassName={"panel"}
        panelChildren={
          <> 
            <label className='font-14-b label'>Předměty</label>
            <input className="input-filter font-16 subjects" placeholder='Zadejte název předmětu...' value={subjectFilter.value} onChange={handleSubjectFilter}/>
            <div className='item-list scrollbar'>
              {filteredSubjects.value.map((object, index) => {
                return (
                  <div key={index} className='item' onClick={() => toggleSelection(object, selectedSubjectIds)}>
                    <input type="checkbox" id={`subject-${object.id}`} checked={selectedSubjectIds.value.includes(object.id)} readOnly />
                    <label htmlFor={`subject-${object.id}`} onClick={() => toggleSelection(object, selectedSubjectIds)}>{object.name}</label>
                  </div>
                )
              })}
            </div>
          </>
        }
      />
      <DropdownPopover className={"dropdown"}
        buttonClassName={"button font-14"}
        buttonChildren={
          <>
            <>Stupeň</>
            <FaChevronDown className="ml-2 h-3 chevron" />
          </>
        }
        panelClassName={"panel"}
        panelChildren={
          <>
            <label className='font-14-b label'>Stupně</label>
            <div className='item-list scrollbar'>
              {gradeTypes.value.map((object, index) => {
                return (
                  <div key={index} className='item' onClick={() => toggleSelection(object, selectedGradeTypeIds)}>
                    <input type="checkbox" id={`gradeType-${object.id}`} checked={selectedGradeTypeIds.value.includes(object.id)} readOnly />
                    <label htmlFor={`gradeType-${object.id}`} onClick={() => toggleSelection(object, selectedGradeTypeIds)}>{object.name}</label>
                  </div>
                )
              })}
            </div>
          </>
        }
      />
      <DropdownPopover className={"dropdown"}
        buttonClassName={"button font-14"}
        buttonChildren={
          <>
            <>Ročník</>
            <FaChevronDown className="ml-2 h-3 chevron" />
          </>
        }
        panelClassName={"panel"}
        panelChildren={
          <> 
            <label className='font-14-b label'>Ročníky</label>
            <div className='item-list scrollbar'>
              {filteredGrades.value.map((object, index) => {
                return (
                  <div key={index} className='item' onClick={() => toggleSelection(object, selectedGradeIds)}>
                    <input type="checkbox" id={`grade-${object.id}`} checked={selectedGradeIds.value.includes(object.id)} readOnly />
                    <label htmlFor={`grade-${object.id}`} onClick={() => toggleSelection(object, selectedGradeIds)}>{object.name}</label>
                  </div>
                )
              })}
            </div>
          </>
        }
      />
      <DropdownPopover className={"dropdown"}
        buttonClassName={"button font-14"}
        buttonChildren={
          <>
            <>Certifikováno</>
            <FaChevronDown className="ml-2 h-3 chevron" />
          </>
        }
        panelClassName={"panel"}
        panelChildren={
          <> 
            <label className='font-14-b label'>Certifikováno</label>
            <div className='item-list scrollbar'>
              <div className='item' onClick={() => {updateFilters("Certifikováno", 1, certificated); certificated.value = toggleStaticSelection(1, certificated.value)}}>
                <input type="checkbox" id="certificated-1" checked={certificated.value.includes(1)} readOnly />
                <label htmlFor="certificated-1" onClick={() => {updateFilters("Certifikováno", 1, certificated); certificated.value = toggleStaticSelection(1, certificated.value)}}>Certifikované</label>
              </div>
              <div className='item' onClick={() => {updateFilters("Bez certifikace", 0, certificated); certificated.value = toggleStaticSelection(0, certificated.value)}}>
                <input type="checkbox" id="certificated-0" checked={certificated.value.includes(0)} readOnly />
                <label htmlFor="certificated-0" onClick={() => {updateFilters("Bez certifikace", 0, certificated); certificated.value = toggleStaticSelection(0, certificated.value)}}>Bez certifikace</label>
              </div>
            </div>
          </>
        }
      />
      <DropdownPopover className={"dropdown"}
        buttonClassName={"button font-14"}
        buttonChildren={
          <>
            <>Jazyk</>
            <FaChevronDown className="ml-2 h-3 chevron" />
          </>
        }
        panelClassName={"panel"}
        panelChildren={
          <> 
            <label className='font-14-b label'>Jazyky</label>
            <div className='item-list scrollbar'>
              {languages.value.map((object, index) => {
                return (
                  <div key={index} className='item' onClick={() => toggleSelection(object, selectedLanguageIds)}>
                    <input type="checkbox" id={`language-${object.id}`} checked={selectedLanguageIds.value.includes(object.id)} readOnly />
                    <label htmlFor={`language-${object.id}`} onClick={() => toggleSelection(object, selectedLanguageIds)}>{object.name}</label>
                  </div>
                )
              })}
            </div>
          </>
        }
      />
      <DropdownPopover className={"dropdown"}
        buttonClassName={"button font-14"}
        buttonChildren={
          <>
            <>Hodnocení</>
            <FaChevronDown className="ml-2 h-3 chevron" />
          </>
        }
        panelClassName={"panel"}
        panelChildren={
          <> 
            <label className='font-14-b label'>Hodnocení</label>
            <div className='item-list scrollbar'>
              <div className='item' onClick={() => {updateFilters("5-4 hvězdy", "5-4", rating); rating.value = toggleStaticSelection("5-4", rating.value)}}>
                <input type="checkbox" id='rating-5-4' checked={rating.value.includes("5-4")} readOnly />
                <label htmlFor='rating-5-4' onClick={() => {updateFilters("5-4 hvězdy", "5-4", rating); rating.value = toggleStaticSelection("5-4", rating.value)}}><span className='number'>5-4</span> <span className='text'>hvězdy</span></label>
              </div>
              <div className='item' onClick={() => {updateFilters("4-3 hvězdy", "4-3", rating); rating.value = toggleStaticSelection("4-3", rating.value)}}>
                <input type="checkbox" id='rating-4-3' checked={rating.value.includes("4-3")} readOnly />
                <label htmlFor='rating-4-3' onClick={() => {updateFilters("4-3 hvězdy", "4-3", rating); rating.value = toggleStaticSelection("4-3", rating.value)}}><span className='number'>4-3</span> <span className='text'>hvězdy</span></label>
              </div>
              <div className='item' onClick={() => {updateFilters("3-2 hvězdy", "3-2", rating); rating.value = toggleStaticSelection("3-2", rating.value)}}>
                <input type="checkbox" id='rating-3-2' checked={rating.value.includes("3-2")} readOnly />
                <label htmlFor='rating-3-2' onClick={() => {updateFilters("3-2 hvězdy", "3-2", rating); rating.value = toggleStaticSelection("3-2", rating.value)}}><span className='number'>3-2</span> <span className='text'>hvězdy</span></label>
              </div>
              <div className='item' onClick={() => {updateFilters("2-1 hvězdy", "2-1", rating); rating.value = toggleStaticSelection("2-1", rating.value)}}>
                <input type="checkbox" id='rating-2-1' checked={rating.value.includes("2-1")} readOnly />
                <label htmlFor='rating-2-1' onClick={() => {updateFilters("2-1 hvězdy", "2-1", rating); rating.value = toggleStaticSelection("2-1", rating.value)}}><span className='number'>2-1</span> <span className='text'>hvězdy</span></label>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default Filter;
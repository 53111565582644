import "./JumpToTop.scss";
import FaArrowUp from 'images/fa-arrow-up.svg?react';

interface JumpToTopProps {
  dissapear: boolean;
}

const JumpToTop = ({dissapear}: JumpToTopProps) => {

  const handleJump = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <button className={`jump-to-top ${dissapear ? "dissapear" : ""}`} onClick={handleJump}>
      <FaArrowUp className="p-green-h"/>
    </button>
  );
};

export default JumpToTop;

import LoadingScreen from "../components/loading-screen/LoadingScreen";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { navigate } from "../redux/navigateSlice";
import Filter from "../components/filter/Filter";
import Navbar from "../components/navbar/Navbar";
import RecommendedUnit from "../components/recommended-unit/RecommendedUnit";
import SearchBar from "../components/searchbar/Searchbar";
import { iTeachingUnit } from "../interfaces/teachingUnit";
import axios from "../utils/axios";
import "./HomePage.scss";
import { getTeachingUnitRowAmount } from "../utils/getTeachingUnitRowAmount";
import { useSearchParams } from "react-router-dom";

import useFilterLogic, {
  filterQuery,
  activeFilters,
  activeTags
} from 'components/filter-logic/useFilterLogic';
import { useModal } from "contexts/ModalProvider";
import ChangePassword from "components/password-reset/ChangePassword";
import Footer from "components/footer/Footer";
import { Helmet } from "react-helmet-async";
import { websiteUrl } from "utils/consts";

const HomePage = () => {
  const [searchParams] = useSearchParams();
  const [teachingUnitQuery, setTeachingUnitQuery] = useState<string>("");
  const [teachingUnits, setTeachingUnits] = useState<iTeachingUnit[]>([]);
  const [searchContext, setSearchContext] = useState<string>("");
  const dispatch = useDispatch();
  const {flushFilters} = useFilterLogic();
  const {showModal} = useModal();
  const [loaded, setLoaded] = useState<boolean>(false);
  const rowAmount = getTeachingUnitRowAmount();

  useEffect(() => {
    flushFilters();
    getRecommended();
    setLoaded(true);
  }, [])

  useEffect(() => {
    if (!searchParams) return;
    const token: string | null = searchParams?.get("token");
    if (!token) return;
    showModal(<ChangePassword token={token}/>);
  }, [loaded])

  useEffect(() => {
    let baseTeachingUnitQuery: string = "?order-by=-number_of_downloads";
    if (activeTags.value.length > 0) {
      const tags: string[] = [...new Set(activeTags.value)];
      baseTeachingUnitQuery = baseTeachingUnitQuery + "&q=" + encodeURI(tags.join(","));
    }
    setTeachingUnitQuery(baseTeachingUnitQuery + filterQuery.value);
  }, [filterQuery.value, activeTags])

  const getRecommended = async () => {
    const pageAmount = rowAmount * 4;
    const newTeachingUnits = await axios.get(`/api/teaching-units/recommended?count=${pageAmount}`);
    if (!newTeachingUnits.data) return;
    setTeachingUnits(newTeachingUnits.data);
  }

  const addTag = (newTag: string) => {
    activeTags.value = [...activeTags.value, newTag];
  };

  const handleSearch = () => {
    const completeQuery = teachingUnitQuery;
    flushFilters();
    if (activeTags.value.length > 0 || activeFilters.value.length > 0) dispatch(navigate("/search" + completeQuery));
    else if (searchContext.length > 0) dispatch(navigate("/search?q=" + encodeURI(searchContext)));
    else (dispatch(navigate("/search" + completeQuery)));
  }

  return (
    <>
      <Helmet>
        <title>Eduklub - Plány výuky pro aplikaci EDUBO</title>
        <meta name="description" content="Eduklub nabízí široký výběr výukových plánů pro aplikaci EDUBO i PDF export pro vlastní plánování výuky. Vyhledejte plány, nahrávejte, stahujte a hodnoťte."/>
        <link rel="canonical" href={`${websiteUrl}/`} />
      </Helmet>
      <div className="homepage">
        <Navbar />
        <div className="homepage-container">
          <h1 className="heading p-blue-h font-36-b">Najděte inspiraci na každou vyučovací hodinu</h1>
          <h2 className="searchbar-text p-blue">
            Využijte náš vyhledávač a pomocí filtrů vstupte do světa objevování vzdělávacích materiálů pro učitele
          </h2>
          <SearchBar activeFilters={activeFilters} addTag={addTag} handleSearch={handleSearch} setSearchContext={setSearchContext} searchContext={searchContext}/>
          <Filter/>
          <h3 className="recommended font-20-b p-blue-h">Doporučujeme</h3>
          {teachingUnits.length === 0 ?
            <div className="loading" style={{minHeight: rowAmount * 18.6875 + "rem"}}>
              <LoadingScreen upper/>
            </div>
          :
            <div className="recommended-cards" style={{minHeight: rowAmount * 18.6875 + "rem"}}>
              {teachingUnits.map((data, index) => {
                return (
                  <RecommendedUnit key={index} {...data} />
                )})}
            </div>
          }
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default HomePage;
import ProfileBadge from "../components/profile-badge/ProfileBadge";
import Info from "../components/profile/Info";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Published from "../components/profile/Published";
import './UserPage.scss';
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import axios from "../utils/axios";
import GeneralModal from "../components/general-modals/GeneralModal";
import { useModal } from "../contexts/ModalProvider";
import { useSnackbar } from "contexts/SnackbarProvider";
import Footer from "components/footer/Footer";
import { Helmet } from "react-helmet-async";
import { websiteUrl } from "utils/consts";

const UserPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [userId, setUserId] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const { showModal, closeModal } = useModal();
  const { openSuccessSnackbar, openErrorSnackbar } = useSnackbar();

  useEffect(() => {
    const userIdParam: string | null = searchParams?.get("id");
    if (userInfo.id && userIdParam && userInfo.id === parseInt(userIdParam)) navigate("/me", { replace: true });
    if (userIdParam && parseInt(userIdParam) > 0 ) setUserId(parseInt(userIdParam));
    else navigate("/");
  }, [])

  useEffect(() => {
    if (userId === 0) return;
    getName();
  }, [userId])

  useEffect(() => {
    if (userInfo.id && userId === userInfo.id) navigate("/me", { replace: true });
  }, [userInfo])

  const getName = async () => {
    const response = await axios.get("/api/users/user/public/" + userId);
    setFirstName(response.data.first_name);
    setLastName(response.data.last_name);
  }

  const deleteAccount = async () => {
    if (!userInfo.is_staff) return;
    closeModal();
    axios.post(`/api/users/${userId}/delete`)
    .then(() => {
      openSuccessSnackbar('Uživatel byl úspěšně smazán!');
      navigate("/");
    })
    .catch((error) => {
      openErrorSnackbar('Uživatele se nepodařilo smazat!');
      console.error(error.response);
    });
  }

  return (
    <>
      <Helmet>
        <title>Eduklub - Profil uživatele</title>
        <meta name="description" content="Eduklub nabízí široký výběr výukových plánů pro aplikaci EDUBO i PDF export pro vlastní plánování výuky. Vyhledejte plány, nahrávejte, stahujte a hodnoťte."/>
        <link rel="canonical" href={`${websiteUrl}/users?id=${searchParams?.get("id")}`} />
      </Helmet>
      <div className="user-page">
        <Navbar />
        {userId > 0 &&
          <div className="user-page-container">
            <h1 className="p-blue font-32 mb-2">Publikované materiály autorem: „{firstName + " " + lastName}“</h1>
            <ProfileBadge userId={userId} large name />
            <div className="flex flex-row items-center">
              <Info userId={String(userId)}/>
              {userInfo.is_staff &&
                <button className="delete-button l-red-bg-h" onClick={() => showModal(<GeneralModal text={"Opravdu chcete smazat tento účet?"} actionOnClick={deleteAccount} />)}>Smazat účet</button>
              }
            </div>
            <Published userId={userId}/>
          </div>
        }
      </div>
      <Footer/>
    </>
  );
};

export default UserPage;
import './LessonPreviewButton.scss'
import ArrowLeft from 'images/arrow-left.svg?react'

const LessonPreviewButton = (props: any) => {
  return (
    <button
      className={`lesson-preview-button font-14 ${props.open ? 'l-blue-bg' : 'white-bg-h'}`}
      onClick={props.onSelect}
    >
      <span>{props.children}</span>
      <ArrowLeft className={`p-blue ${props.open ? "open" : ""}`}/>
    </button>
  )
}
export default LessonPreviewButton;